import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Success = () => (
  <Layout>
    <SEO title="Work with WebPerfex" />
<div className="container py-12">
  <h1 className="text-center text-4xl text-semibold">Thank you!</h1>
</div>
  </Layout>
)

export default Success
